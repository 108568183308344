// import enterpriseList from './enterpriseList.vue'
import { computed } from 'vue'
import { getToken } from '@/utils/tools'
import axios from "axios";
export default function (
    store,
    route,
    router,
    tableColumnConfig, //表格表头
    formDialogOption, //表单弹窗相关配置
    searchParams, //搜索条件
    searchOption, //顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery, //模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery, //条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams, //页面相关配置
    tableRequestOption, //表格获取数据相关配置
    tableOption, //表格属性配置
    tableDelDataOption, //数据删除相关配置
    handleEventObj, //顶部及右侧操作按钮事件
    customBtnEvent, //自定义事件
    _axios, //网络请求
    showToast, //弹窗
    showConfirm,
    conditionQuery, //条件搜索触发事件对象
    fileInput, //上传文件的dom对象
    inputFn, //input
    navBarTxt, //按钮配置
    getTableData
) {
    //多选
    tableOption.selection = true
    const subMenuItem = computed(() => store.state.page.subMenuItem)
    const basciBtn = { children: [] }
    const buildingBtn = { children: [] }
    subMenuItem.value.children.map(v => {
        if (v.permission == 10) {
            basciBtn.children.push(v)
        }
    })
    store.commit("page/setCurrentMenuItem", basciBtn);

    formDialogOption.dialogWidth = "40%";
    searchOptionsFuzzyQuery.val = {
        keys: "name",
        placeholder: "楼栋名称",
    };
    

    //设置新建表单默认值
    formDialogOption.defaultItem = {
        type: '1',
        isSystemAir: 1,
        isIntelligentize: 1,
        isCenterAir: 1,
        isNonMotor: 1,
        flexibility: 1,
        isPartyMasses: 1,
        isCanteen: 1,
        isExpressCounters: 1,
        isSupermarket: 1,
        ...JSON.parse(route.query.extra),
    };

    tableRequestOption.url = "/sys/buildingPart/page";
    tableRequestOption.params = {
        ...JSON.parse(route.query.extra),
    };
    //删除参数
    tableDelDataOption.method = "post";
    tableDelDataOption.url = "/sys/buildingPart/delete";
    tableDelDataOption.tips= "删除楼栋将删除所有与之相关联的数据，确定这样做吗？"
    tableDelDataOption.type = "bodyArr"
    // tableDelDataOption.valkey='dictId'

    //楼层
    handleEventObj.floor = (item) => {
        //钻取下级通用列表传参配置
        router.push({
            path: `/building/floorInfo`,
            query: {
                icon: "back", //返回
                title: "楼层", //顶部左侧标题
                extra: JSON.stringify({ buildingPartId: item.id }), //其他额外携带参数,有的话再传
            },
        });
    };

    
    handleEventObj.bindingEnterprise = () => {

    }
}
