
export const tableConfig=[
    {
        prop:'userName',
        label:'账号'
    },
    {
        prop:'user',
        label:'核验人姓名'
    },
    {
        prop: "userPhone",
        label: "核验人电话",
    },
    {
        prop: "type",
        label: "处理状态",
        type:"state",
        option:[
            {label:"待办",value:0},
            {label:"已核验",value:1}
        ]
    },
    {
        prop: "enterpriseName",
        label: "企业名称", 
    },
    {
        prop: "industry",
        label: "所属行业", 
    },
    {
        prop: "enterpriseAdress",
        label: "企业注册地址", 
    },
    {
        prop: "unifiedCreditCode",
        label: "统一社会信用代码", 
    },
    {
        prop: "contacts",
        label: "联系人", 
    },
    {
        prop: "phone",
        label: "联系人电话", 
    },
    {
        prop: "registerType",
        label: "在地注册情况",
        type:"state",
        option:[
            {label:"在地不在册",value:1},
            {label:"在册不在地",value:2},
            {label:"在地在册",value:3}
        ]
    },
    {
        prop: "taxPaymentPlace",
        label: "纳税地", 
    },
    {
        prop: "staff",
        label: "员工人数", 
    },
    {
        prop: "scaleArea",
        label: "租用场地大小", 
    },
    {
        prop: "outputValue",
        label: "产值", 
    },
    {
        prop: "province",
        label: "所在地省份", 
    },
    {
        prop: "city",
        label: "所在地市", 
    },
    {
        prop: "county",
        label: "所在地区/县", 
    },
    {
        prop: "street",
        label: "街道办事处", 
    },
    {
        prop: "building",
        label: "大楼", 
    },
    {
        prop: "buildingNumber",
        label: "大楼号", 
    },
    {
        prop: "floor",
        label: "楼层", 
    },
    {
        prop: "room",
        label: "房号", 
    },
    {
        prop: "createTime",
        label: "创建时间"
    },
	{
	    prop: "updateTime",
	    label: "更新时间"
	}
]
