export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj
){
    formOption.labelWidth = 160
    const prop = formOption.formProp
    formOption.formProp = {
        provinceRatal:prop.provinceRatal,
        cityRatal:prop.cityRatal,
        areaRatal:prop.cityRatal,
        ratalYear:prop.ratalYear,
        buildingPartId:prop.id
    }
    formOption.newApiOption.api = '/sys/ratal/saveOrUpdate'
    
   
  
    // formOption.nextStep = 'all'
    // formOption.newApiOption.api = `/ratal/saveOrUpdate`
    // formOption.updateApiOption.api = `/sys/yunke/mgr/updateAdminInfo`
    // fromHndleEventObj.myCustomEvent = (formData)=>{
    //     clickBtnSelectObj.clickBtnShowCom = true
    //     clickBtnSelectObj.title = '规格--属性'
    //     clickBtnSelectObj.size = '30%'
    //     clickBtnSelectObj.cpname = 'attrbute'
    // }
}
