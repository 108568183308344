import {computed} from "vue"
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    searchOption.hasSearch = false

     // 权限设置
     const subMenuItem = computed(()=>store.state.page.subMenuItem)
     const basciBtn = {children:[]}
     subMenuItem.value.children.map(v=>{
         if(v.permission==30){
             basciBtn.children.push(v)
         }
     })
     store.commit("page/setCurrentMenuItem", basciBtn);

    //设置新建表单默认值
    formDialogOption.defaultItem = {buildingId:route.query.id}
    formDialogOption.dialogWidth = "40%"

    tableRequestOption.params = {buildingId:route.query.id}
    tableRequestOption.url = '/sys/buildingRotation/page'

    //删除参数
    tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/buildingRotation/delete'
    tableDelDataOption.type = 'bodyArr'
}

