export const tableConfig=[ //表单
  {
    prop:'num',
    label:'序号'
  },
  {
    prop:'streetName',
    label:'街道名称'
  },
  {
    prop:'projectNum',
    label:'楼宇项目数（个）'
  },
  {
    prop:'buildingNum',
    label:'楼宇数量（栋）'
  },
  {
    prop:'totalArea',
    label:'总建筑面积（㎡）'
  }
]

// export const ringConfig=[//圆环图
//     {
//       key:'',
//       data:{
//         title: {
//           text: '',
//           left: 'center'
//         },
//         tooltip: {
//           trigger: 'item'
//         },
//         legend: {
//           show:false
//         },
//         series: [
//           {
//             name: 'Access From',
//             type: 'pie',
//             radius: '50%',
//             data: [
//               { value: 1048, name: 'Search Engine' },
//               { value: 735, name: 'Direct' },
//               { value: 580, name: 'Email' },
//               { value: 484, name: 'Union Ads' },
//               { value: 300, name: 'Video Ads' }
//             ],
//             emphasis: {
//               itemStyle: {
//                 shadowBlur: 10,
//                 shadowOffsetX: 0,
//                 shadowColor: 'rgba(0, 0, 0, 0.5)'
//               }
//             }
//           }
//         ]
//       }
//   }
// ]

// export const histogramConfig = {//柱状图
//     categories: [],
//     series: [
//         {
//             name: "",
//             data: []
//         }
//     ]
// };
 
// export const lineConfig =  {//折线图
//     categories: [],
//     series: [
//       {
//         name: "",
//         data: []
//       }
//     ]
//   }