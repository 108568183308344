export const tableConfig=[
    {
    	prop:'name',
    	label:'字段名'
    },
    {
    	prop:'code',
    	label:'字段值'
    },
    {
        prop:'type',
    	label:'类型',
        type:'state',
        option:[
            {label:1,value:1},
            {label:2,value:2},
            {label:3,value:3},
            {label:4,value:4},
            {label:5,value:5},
            {label:6,value:6},
            {label:7,value:7},
            {label:8,value:8},
            {label:9,value:9},
            {label:10,value:10},
            {label:11,value:11},
            {label:12,value:12},
            {label:13,value:13},
            {label:14,value:14},
            {label:15,value:15},
            {label:16,value:16},
            {label:17,value:17},
            {label:18,value:18},
            {label:19,value:19},
            {label:20,value:20},
        ]
    },
    {
        prop:'status',
    	label:'状态',
        type:'state',
        option:[
            {label:"禁用",value:0},
            {label:"启用",value:1},
        ]
    }
]

export const formConfig=[
    {
    	prop:'name',
    	label:'字段名'
    },
    {
    	prop:'code',
    	label:'字段值'
    },
    {
        prop:'type',
    	label:'类型',
        type:'select',
        option:[
            {label:1,value:1},
            {label:2,value:2},
            {label:3,value:3},
            {label:4,value:4},
            {label:5,value:5},
            {label:6,value:6},
            {label:7,value:7},
            {label:8,value:8},
            {label:9,value:9},
            {label:10,value:10},
            {label:11,value:11},
            {label:12,value:12},
            {label:13,value:13},
            {label:14,value:14},
            {label:15,value:15},
            {label:16,value:16},
            {label:17,value:17},
            {label:18,value:18},
            {label:19,value:19},
            {label:20,value:20},
        ]
    },
    {
        prop:'status',
    	label:'状态',
        type:'select',
        option:[
            {label:"禁用",value:0},
            {label:"启用",value:1},
        ]
    }
]