export const tableConfig=[
	{
		prop:'name',
		label:'楼宇名称'
	},
	{
		prop:'year',
		label:'年份'
	},
	{
		prop:'anotherName',
		label:'楼宇别名或曾用名'
	}

]

export const formConfig=[
    {
    	prop:'name',
    	label:'楼宇名称'
    },
	{
		prop:'year',
		label:'年份'
	},
    {
    	prop:'anotherName',
    	label:'楼宇别名或曾用名',
		required:false
    }
]