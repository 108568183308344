export const tableConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "level",
        label: "级别",
        type:'state',
        option:[
            {label:"省",value:1},
            {label:"市",value:2},
            {label:"区",value:3},
            {label:"街道",value:4},
        ]
    },
    {
        prop: "year",
        label: "年份",
    },
    {
        prop: "areaName",
        label: "区域名称",
    },
    {
        prop: "content",
        label: "展示模块",
        type:'tagsArrStr',
        key:'label',
        width:500
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        option:[
            {label:"禁用",value:0},
            {label:"启用",value:1},
        ]
    }
]

export const formConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "level",
        label: "级别",
        type:'select',
        option:[
            {label:"省",value:1},
            {label:"市",value:2},
            {label:"区",value:3},
            {label:"街道",value:4},
        ]
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        option:[
            {label:"禁用",value:0},
            {label:"启用",value:1},
        ]
    }
]

export const editFormConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        option:[
            {label:"禁用",value:0},
            {label:"启用",value:1},
        ]
    }
]