export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,
    showConfirm,
    conditionQuery,//条件搜索触发事件对象
    fileInput,//input文件上传dom
    inputFn,//input函数对象
){
	//搜索
    searchOption.hasSearch =false
  
    formDialogOption.defaultItem = {
		status:1
	}
    tableRequestOption.url = '/sys/memberCondition/page'
    
    //删除参数
    // tableDelDataOption.method = 'get'
    tableDelDataOption.url = '/sys/memberCondition/delete'
    tableDelDataOption.type='bodyArr'

    // handleEventObj.building = (item)=>{//钻取下级通用列表传参配置
    //     router.push({
    //         path:`/entering/buildingInfo`,
    //         query:{
	// 			icon:'back',//返回
	// 			title:'税收',//顶部左侧标题
	// 			extra:JSON.stringify({buildingId:item.id}),//其他额外携带参数,有的话再传
    //         }
    //     })
    // }
}
