import _axios from "@/plugins/axios"

export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj
){
    // formOption.labelWidth = 160
    formOption.newApiOption.api = `/sys/keyIndustryAnalysis/addOrModify`

    getBuildingList()
    async function getBuildingList(){
        const res = await _axios('get',`/sys/buildingPart/getByBuildingId?buildingId=${JSON.parse(route.query.extra).buildingId}`,{},true)
        formColumnConfig[0].option = res
        formColumnConfig[0].lkey = 'name'
        formColumnConfig[0].vkey = 'id'
    }
}
