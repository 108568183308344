
export const tableConfig = [
    {
        prop: 'account',
        label: '账户'
    },
    {
        prop: "userType",
        label: "用户类型",
        type: "state",
        option: [
            { label: "内部用户", value: 1 },
            { label: "外部用户", value: 2 }
        ]
    },
    {
        prop: "nickName",
        label: "用户昵称",
    },
    {
        prop: "realName",
        label: "姓名",
    },
    {
        prop: "userMobile",
        label: "手机号",
    },
    {
        prop: "roleVo",
        label: "绑定角色",
        type:"tags",
        key:"name",
        width:400,
        closable:true
    },
    {
        prop: "memberList",
        label: "绑定会员",
        type:"tags",
        key:"memberName",
        closable:true
    },
    {
        prop: "dueDate",
        label: "到期时间",
    },
    {
        prop: "status",
        label: "状态",
        type: "state",
        option: [
            { label: "无效", value: 0 },
            { label: "正常", value: 1 }
        ]
    },
    {
        prop: "createTime",
        label: "创建时间"
    }
]
export const formConfig = [
    {
        prop: 'account',
        label: '账户',
        // type:'phone'
    },
    {
        prop: "userType",
        label: "用户类型",
        type: "select",
        option: [
            { label: "内部用户", value: 1 },
            { label: "外部用户", value: 2 }
        ]
    },
    {
        prop: 'loginPassword',
        label: '登录密码'
    },
    {
        prop: "nickName",
        label: "用户昵称",
        // required:false,
    },
    {
        prop: "realName",
        label: "姓名",
        // required:false,
    },
    {
        prop: "userMobile",
        label: "手机号",
        type:'phone'
    },
    {
        prop: "status",
        label: "状态",
        type: "select",
        option: [
            { label: "无效", value: 0 },
            { label: "正常", value: 1 }
        ]
    },
]
export const editFormConfig = [
    {
        prop: 'account',
        label: '账户',
        // type:'phone'
    },
    {
        prop: "userType",
        label: "用户类型",
        type: "select",
        option: [
            { label: "内部用户", value: 1 },
            { label: "外部用户", value: 2 }
        ]
    },
    {
        prop: "nickName",
        label: "用户昵称",
    },
    {
        prop: "realName",
        label: "姓名",
    },
    {
        prop: "userMobile",
        label: "手机号",
        type:'phone'
    },
    {
        prop: "status",
        label: "状态",
        type: "select",
        option: [
            { label: "无效", value: 0 },
            { label: "正常", value: 1 }
        ]
    },
]
