<template>
    <div class="mask" @click="close">
        <div class="background" @click.stop="">
            <div class="listBox first" >
                <div class="title">
                    <div class="Tname"> 已绑定企业列表</div>
                    <el-button type="primary" @click="deleteList">解绑</el-button>
                </div>
                <div class="scroll" @scroll="alreadyLoding">
                    <el-checkbox-group class="listItem" v-model="state.alreadyGroupList">
                        <el-checkbox v-for="(item, index) in state.alreadyList" :key="index" :label="item.id">
                            {{ item.enterpriseName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="listBox" @click.stop="">
                <div class="title">
                    <div class="Tname"> 企业列表</div>
                    <el-button type="primary" @click="binding">绑定</el-button>
                </div>

                <div class="inputBox">
                    <el-input clearable v-model="state.input" @input="search" placeholder="请输入企业名字查询" />
                    <el-button class="btn" type="primary">搜索</el-button>
                </div>
                <div class="scroll" @scroll="loding">
                    <el-checkbox-group class="listItem" v-model="state.groupList">
                        <el-checkbox v-for="(item, index) in state.list" :key="index" :label="item.id">
                            {{ item.enterpriseName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>

                <!-- <div class="pages">
                <el-button class="text" @click="addList(0)">上一页</el-button>
                <div class="num">
                    {{state.current}}
                        /
                    {{state.pages}}
                </div>
                <el-button class="text" @click="addList(1)">下一页</el-button>
            </div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "enterpriseList"
}
</script>
<script setup>
import _axios from '../../plugins/axios';
import { reactive } from 'vue'
import showToast from '../../common/showToast.js'

const props = defineProps({
    removeDivle: {
        type: Function,
        default: () => {
            console.log()
        }
    },
    item: {
        type: Object,
        default: () => { return {} }
    }
})
let timer;
function debounce(fn, delay) {
    clearTimeout(timer);
    timer = setTimeout(function () {
        fn();
    }, delay);

}
const state = reactive({
    input: '',
    list: [],//全部企业列表
    groupList: [],//选中的企业列表
    pageNum: 1,
    pageSize: 15,
    current: '1',
    pages: '0',

    alreadyList: [],//楼栋下的企业列表
    alreadyGroupList: [],//楼栋下选中的企业列表
    alreadyPageNum: 1,
    alreadyPageSize: 16,
    alreadycurrent: '1',
    alreadypages: '0',
})
const reset = () => {
    state.alreadyList = []
    state.alreadyGroupList = []
    state.alreadyPageNum = 1

    state.pageNum = 1

    state.groupList = []

}
const close = () => {
    props.removeDivle()
}
const search = () => {
    debounce(() => {
        state.list = []
        state.pageNum = 1
        getList()
    }, 200)

}
function uniqueFunc(arr, uniId) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}
const binding = async () => {//绑定

    // let enterpriseList = state.list.filter((item)=>{
    //     let groupId = state.groupList.find((cItem)=> cItem == item.id )
    //     return groupId == item.id
    // })
    // enterpriseList = [...props.item.enterpriseList,...enterpriseList]
    // enterpriseList = uniqueFunc(enterpriseList,'id')
    // let params = {
    //     ...props.item,
    //     enterpriseList
    // }
    if(!state.groupList.length) return showToast.warning("请选择要绑定的企业")
    let params = [

    ]
    state.groupList.forEach((enterpriseId) => {
        params.push({
            buildingFloorId: props.item.id,
            enterpriseId
        })
    })
    const res = await _axios('post', '/sys/buildingFloor/addEnterprise', params, true)
    // console.log(res)
    showToast.success(res)
    reset()
    getAlreadyList()

}
const deleteList = async () => {
    // let enterpriseList = state.alreadyList.filter((item)=>{
    //     let groupId = state.alreadyGroupList.find((cItem)=> cItem == item.id )
    //     return groupId != item.id
    // })
    // let params = {
    //     ...props.item,
    //     enterpriseList
    // }
    if(!state.alreadyGroupList.length) return showToast.warning("请选择要解绑的企业")
    let params = [

    ]
    state.alreadyGroupList.forEach((enterpriseId) => {
        params.push({
            buildingFloorId: props.item.id,
            enterpriseId
        })
    })
    const res = await _axios('post', '/sys/buildingFloor/deleteEnterprise', params, true)
    showToast.success('已解绑')
    reset()
    getAlreadyList()
}
const addList = (num) => {
    if (num && +state.pages > +state.current) {
        state.pageNum += 1
    } else if (state.pageNum >= 2 && !num) {
        state.pageNum -= 1
    } else {
        return
    }

    getList()
}
const loding = (e) => {
    debounce(() => {
        let num = e.srcElement.scrollTop + e.srcElement.offsetHeight
        let sum = e.srcElement.scrollHeight - num

        if (sum <= 100) {
            if (num && +state.pages > +state.current) {
                state.pageNum += 1
                getList()
            }
        }
    }, 200)

}
const alreadyLoding = (e) => {
    debounce(() => {
        let num = e.srcElement.scrollTop + e.srcElement.offsetHeight
        let sum = e.srcElement.scrollHeight - num

        if (sum <= 100) {
            if (num && +state.alreadypages > +state.alreadycurrent) {
                state.alreadyPageNum += 1
                getAlreadyList()
            }
        }
    }, 200)
}
getList()
console.log(props.item)
async function getList() {
    const res = await _axios('post', '/sys/enterprise/page', {
        pageNum: state.pageNum,
        pageSize: state.pageSize,
        enterpriseName: state.input,
        year:props.item.year
    }, true)
    state.current = res.current
    state.pages = res.pages
    state.list = [...state.list, ...res.records]
    // state.groupList = JSON.parse(JSON.stringify(state.list))
}
getAlreadyList()
async function getAlreadyList() { //获取楼栋下的企业列表
    console.log(props.item)
    const res = await _axios('post', '/sys/enterprise/pageByFloorId', {
        id:props.item.id,
        pageNum: state.alreadyPageNum,
        pageSize: state.alreadyPageSize,

    }, true)
    state.alreadycurrent = res.current
    state.alreadypages = res.pages
    state.alreadyList = [...state.alreadyList, ...res.records]
}
</script>
<style lang="scss" scoped>
.mask {
    position: fixed;
    display: flex;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: center;
    .background{
        background: #fff;
        display: flex;
        border-radius: 10px;
        padding: 15px;
    }
    .listBox {
        background-color: #fff;
        box-sizing: border-box;
        padding: 15px;
        border-radius: 10px;
        width: 400px;
        height: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 4px 4px 4px rgba(13, 17, 57, 0.1);

        .title {
            margin-bottom: 20px;
            width: 100%;

            display: flex;
            justify-content: space-between;

            .Tname {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .scroll {
            overflow: scroll;
            width: 400px;
            height: calc(100% - 50px);
            padding: 0 15px 15px 15px;
        }

        .inputBox {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn {
                margin-left: 30px;
            }
        }

        .pages {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;

        }

        .listItem {
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            align-items: flex-start;

            .name {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }

    .first {
        margin-right: 50px;
    }
}
</style>