export const tableConfig=[
    {
        prop: "enterpriseName",
        label: "名称",
        width:'200'
    },
    {
        prop: "dataYear",
        label: "数据年份",
    },
    {
        prop: "establishmentDate",
        label: "成立日期",
    },
    {
        prop:'industry',
        label:'所属行业',
    },
    {
        prop:'enterpriseType',
        label:'公司类型',
    },
    {   
        prop:'area',
        label:'所属区县',
    },
   
    {   
        prop:'businessStatus',
        label:'经营状态',
    },
    {   
        prop:'businessTerm',
        label:'营业期限',
    },
    {   
        prop:'contactNumber',
        label:'联系电话',
    },
    {   
        prop:'contributedCapital',
        label:'实缴资本',
    },
    {   
        prop:'declareArea',
        label:'申报区县',
    },
    {   
        prop:'declareYearHead',
        label:'总部企业申报年份',
    },
    {   
        prop:'formerName',
        label:'曾用名',
    },
    {   
        prop:'legalRep',
        label:'法定代表人',
    },
    {   
        prop:'organizationCode',
        label:'组织机构代码',
    },
    {   
        prop:'registerAddress',
        label:'注册地址',
    },
    {   
        prop:'registeredCapital',
        label:'注册资本',
    },
    {   
        prop:'remarks',
        label:'备注',
    },
    {   
        prop:'socialSecurityNum',
        label:'社保人数',
    },
    {   
        prop:'unifiedSocialCreditCode',
        label:'统一社会信用代码',
    },
    {   
        prop:'businessScope',
        label:'经营范围',
        type:"textarea",
    },
]

export const formConfig=[
    {
        prop: "enterpriseName",
        label: "名称",
        required:false,
    },
    {
        prop: "dataYear",
        label: "数据年份",
        required:false,
    },
    {
        prop: "establishmentDate",
        label: "成立日期",
        // type:'datetime',
        required:false,
    },
    {
        prop:'industry',
        label:'所属行业',
        required:false
    },
    {
        prop:'enterpriseType',
        label:'公司类型',
        required:false
    },
    {   
        prop:'area',
        label:'所属区县',
        required:false
    },
    
    {   
        prop:'businessStatus',
        label:'经营状态',
        required:false
    },
    {   
        prop:'businessTerm',
        label:'营业期限',
        // type:'datetime',
        required:false
    },
    {   
        prop:'contactNumber',
        label:'联系电话',
        required:false
    },
    {   
        prop:'contributedCapital',
        label:'实缴资本',
        required:false
    },
    {   
        prop:'declareArea',
        label:'申报区县',
        required:false
    },
    {   
        prop:'declareYearHead',
        label:'总部企业申报年份',
        required:false
    },
    {   
        prop:'formerName',
        label:'曾用名',
        required:false
    },
    {   
        prop:'legalRep',
        label:'法定代表人',
        required:false
    },
    {   
        prop:'organizationCode',
        label:'组织机构代码',
        required:false
    },
    {   
        prop:'registerAddress',
        label:'注册地址',
        required:false
    },
    {   
        prop:'registeredCapital',
        label:'注册资本',
        required:false
    },
    {   
        prop:'remarks',
        label:'备注',
        type:"textarea",
        required:false
    },
    {   
        prop:'socialSecurityNum',
        label:'社保人数',
        required:false
    },
    {   
        prop:'unifiedSocialCreditCode',
        label:'统一社会信用代码',
        required:false
    },
    {   
        prop:'businessScope',
        label:'经营范围',
        type:"textarea",
        required:false
    },
]

