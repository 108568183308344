export const tableConfig = [
	{
		prop:'name',
		label:'楼栋名称'
	},
	{
		prop:'year',
		label:'年份'
	},
]

export const formConfig = [
    {
		prop: 'provinceRatal',
		label: '省纳税额',
		required:false,
		type:'number'
	},
	{
		prop: 'cityRatal',
		label: '市纳税额',
		required:false,
		type:'number'
	},
	{
		prop: 'areaRatal',
		label: '区纳税额',
		required:false,
		type:'number'
	},
]
