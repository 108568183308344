import axios from "axios";
import { getToken } from '@/utils/tools'
import { computed } from "vue";
import { baseUrl } from "@/main";
import { operateInProcess } from "@/components/operateInProcess/operateInProcess";
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,
    showConfirm,
    conditionQuery,//条件搜索触发事件对象
    fileInput,//input文件上传dom
    inputFn,//input函数对象
    navBarTxt,
    getTableData,//获取列表
    drawerOption
){
     //设置新建表单默认值
     formDialogOption.defaultItem = {
		isNonMotor:0,
		flexibility:0,
        parkUp:0,
        parkDown:0,
		isPartyMasses:0,
		isCanteen:0,
		isExpressCounters:0,
		isSupermarket:0,
        isCenterAir:0,
        isSystemAir:0
	}

    tableOption.customBack = true

    //伪造按钮
	const obj = {
		children:[
			
			{
				createTime: "2022-10-08T11:01:36",
				code: "top",
				icon: '{"name":"主要","type":"primary","plain":false}',
				name: "抓取数据",
				uri: "await",
			},
			{
				createTime: "2022-10-08T11:01:36",
				code: "top",
				icon: '{"name":"主要","type":"warning","plain":false}',
				name: "批量删除",
				uri: "delDataBatch",
			},
			{
				createTime: "2022-10-08T11:01:36",
				code: "right",
				icon: '{"name":"主要","type":"primary","plain":false}',
				name: "企业分析",
				uri: "analyse",
			},
			{
			  createTime: "2022-10-08T11:01:36",
			  code: "right",
			  icon: '{"name":"主要","type":"primary","plain":false}',
			  name: "详情",
			  uri: "viewData",
			},
			{
				createTime: "2022-10-08T11:01:36",
				code: "right",
				icon: '{"name":"主要","type":"primary","plain":false}',
				name: "空置原因",
				uri: "analyse1076",
			},
			{
			  createTime: "2022-10-08T11:01:36",
			  code: "right",
			  icon: '{"name":"主要","type":"primary","plain":false}',
			  name: "楼栋",
			  uri: "building",
			},
			{
			  createTime: "2022-10-08T11:01:36",
			  code: "right",
			  icon: '{"name":"主要","type":"primary","plain":false}',
			  name: "详情轮播",
			  uri: "detailSwiper",
			},
			{
			  createTime: "2022-10-08T11:01:36",
			  code: "right",
			  icon: '{"name":"危险","type":"danger","plain":false}',
			  name: "删除",
			  uri: "delData",
			},
		  ]
	};
	
	store.commit("page/setCurrentMenuItem", obj);

    //多选
    tableOption.selection = true

    // 权限设置
    const subMenuItem = computed(()=>store.state.page.subMenuItem)
    const basciBtn = {children:[]}
    subMenuItem.value.children.map(v=>{
        if(v.permission==2){
            basciBtn.children.push(v)
        }
    })
    store.commit("page/setCurrentMenuItem", basciBtn);

    
	//搜索
    searchOption.searchType = 'conditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '名称', prop: 'name', type: 'text'},
		{ label: '年份', prop: 'year', type: 'year' },
        { label: '省', prop: 'provinceId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '市', prop: 'cityId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '区/县', prop: 'areaId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '街道办', prop: 'streetId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
    ]
  
    conditionQuery.getSelectVal = (e)=>{ //获取省市区街道
        const [sitem, item] = e
        if (item.prop == 'provinceId') {
            getProvince(sitem.areaId, 'province')
        }
        if (item.prop == 'cityId') {
            getProvince(sitem.areaId, 'city')
        }
        if (item.prop == 'areaId') {
            getProvince(sitem.areaId, 'area')
        }
		if (item.prop == 'streetId') {
		   
		}
    }
   
    
    tableRequestOption.url = '/sys/buildingDetail/page'
    
    //删除参数
    tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/buildingDetail/delete'
    tableDelDataOption.tips= "删除楼宇将删除所有与之相关联的数据，确定这样做吗？"
    tableDelDataOption.type = "bodyArr"
    // tableDelDataOption.valkey='dictId'

    // 楼栋
    handleEventObj.building = (item)=>{//钻取下级通用列表传参配置
        router.push({
            path:`/buildingSingle/buildingInfo`,
            query:{
				icon:'back',//返回
				title:'楼栋',//顶部左侧标题
				extra:JSON.stringify({buildingId:item.id}),//其他额外携带参数,有的话再传
            }
        })
    }

     // 详情轮播
     handleEventObj.detailSwiper = (item)=>{//钻取下级通用列表传参配置
        router.push({
            path:`/building/detailSwiper`,
            query:{
				icon:'back',//返回
				title:'楼宇',//顶部左侧标题
                id:item.id
            }
        })
    }

    // excel导入
    handleEventObj.excelImport = async (e)=>{
        fileInput.value.click()
    }
    // excel导入
    inputFn.file = async (e)=>{
        operateInProcess({show:true,title:'正在导入···'})
        let files = e.target.files || e.dataTransfer.files;
        let formData = new FormData();
        formData.append('file', files[0])
        const res =  await _axios('post','/sys/buildingDetail/import',formData,true)
        operateInProcess({show:false})
        showToast.success("导入成功");
        fileInput.value.value = ''
        getTableData()
    }

    // 模板导出
    handleEventObj.exportTpl = ()=>{
        // operateInProcess({show:true,title:'正在导出···'})
        axios({
            data: {},
            method: 'get',
            url: baseUrl + `/sys/buildingDetail/template`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'yk_wx_admin': getToken("token")
            },
            responseType: 'blob',
            dataType: 'json'
        }).then(res => {
            // 
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
            const fileName = '数据模板.xls';
            const elink = document.createElement('a');
            if ('download' in elink) {
                // 非IE下载
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob);
            }
        }).catch(err=>{
            showToast.error('导出模板出错')
        });
    }
//    导出数据
    handleEventObj.excelExport = ()=>{ 
        drawerOption.show = true
        drawerOption.direction = 'ttb'
        drawerOption.title = '导出数据'
        drawerOption.size = '40%'
        drawerOption.customCopName = 'buildingExport'
    }

	
	// 获取省份
    getProvince()
	async function getProvince(id = 1, type = '') {
	    const res = await _axios('get', `/sys/area/listByPid?pid=${id}`, {}, true)
	    if (type == 'province') {
	       searchOptionsConditionQuery.options[3].options = res
	    } else if (type == 'city') {
	       searchOptionsConditionQuery.options[4].options = res
	    } else if (type == 'area') {
	       searchOptionsConditionQuery.options[5].options = res
	    } else {
	       searchOptionsConditionQuery.options[2].options = res
	    }
	}
}
