export const tableConfig=[
	{
		prop:'label',
		label:'楼层名'
	},
	// {
	// 	prop: 'type',
	// 	label: '楼层类型',
	// 	type: "state",
	// 	option: [{
	// 			label: '塔楼',
	// 			value: 1
	// 		},
	// 		{
	// 			label: '裙楼',
	// 			value: 0
	// 		},
	// 	]
	// },
	
	// {
	// 	prop:'num',
	// 	label:'楼层'
	// },
	{
		prop:'signalArea',
		label:'单层面积(㎡)'
	},
	{
		prop:'signalAreaUnused',
		label:'单层空置面积(㎡)'
	},
	{
		prop:'mark',
		label:'空置原因'
	}
]

export const formConfig=[
    {
		prop:'label',
		label:'楼层名',
    },
	// {
	// 	prop:'type',
	// 	label:'楼层类型',
	// 	type:'radio',
	// 	required:false,
	// 	option:[
	// 		{label:'塔楼',value:1},
	// 		{label:'裙楼',value:0},
	// 	]
	// },
	// {
	// 	prop:'num',
	// 	label:'楼层',
	// 	required:false
	// },
	{
		prop:'signalArea',
		label:'单层面积(㎡)',
		required:false
	},
	{
		prop:'signalAreaUnused',
		label:'单层空置面积(㎡)',
		required:false
	},
	{
		prop:'mark',
		label:'空置原因',
		required:false
	}
]