export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,
    showConfirm,
    conditionQuery,//条件搜索触发事件对象
    fileInput,//input文件上传dom
    inputFn,//input函数对象
){




	formDialogOption.dialogWidth = '40%'
	//搜索
    searchOption.searchType = 'conditionQuery'
    // 崔 -- 必须选到街道
    searchOptionsConditionQuery.options = [
        { label: '名称', prop: 'buildingPartName', type: 'text'},
		{ label: '年份', prop: 'ratalYear', type: 'year' },
        { label: '省', prop: 'provinceId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '市', prop: 'cityId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '区/县', prop: 'areaId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '街道办', prop: 'streetCode', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
    ]
  
    conditionQuery.getSelectVal = (e)=>{ //获取省市区街道
        const [sitem, item] = e
        if (item.prop == 'provinceId') {
            getProvince(sitem.areaId, 'province')
        }
        if (item.prop == 'cityId') {
            
            getProvince(sitem.areaId, 'city')
        }
        if (item.prop == 'areaId') {
            
            getProvince(sitem.areaId, 'area')
        }
		if (item.prop == 'streetCode') {
            // console.log(4);
            // getProvince(sitem.areaId, 'street')
		}
    }
    //设置新建表单默认值
    formDialogOption.defaultItem = {
		isNonMotor:1,
		flexibility:1,
        parkUp:0,
        parkDown:0,
		isPartyMasses:1,
		isCanteen:1,
		isExpressCounters:1,
		isSupermarket:1
	}
    tableRequestOption.url = '/sys/ratal/page'
    
    //删除参数
    tableDelDataOption.method = 'DELETE'
    tableDelDataOption.url = '/ratal/delete/'
    // tableDelDataOption.valkey='dictId'

    handleEventObj.building = (item)=>{//钻取下级通用列表传参配置
        router.push({
            path:`/entering/buildingInfo`,
            query:{
				icon:'back',//返回
				title:'税收',//顶部左侧标题
				extra:JSON.stringify({buildingId:item.id}),//其他额外携带参数,有的话再传
            }
        })
    }
	handleEventObj.excleImport = ()=>{
        let input = fileInput.value
        inputFn.file = (target)=>{
            
        }
        input.click()
    }
	getProvince()
	// 获取省份
	async function getProvince(id = 1, type = '') {
	    const res = await _axios('get', `/sys/area/listByPid?pid=${id}`, {}, true)
	    if (type == 'province') {
	       searchOptionsConditionQuery.options[3].options = res
	    } else if (type == 'city') {
	       searchOptionsConditionQuery.options[4].options = res
	    } else if (type == 'area') {
	       searchOptionsConditionQuery.options[5].options = res
	    } else {
	       searchOptionsConditionQuery.options[2].options = res
	    }
	
	}
}
