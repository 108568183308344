export const tableConfig=[ //表单
  {
    prop:'num',
    label:'序号'
  },
  {
    prop:'streetName',
    label:'街道名称'
  },
  {
    prop:'projectNum',
    label:'楼宇项目数（个）'
  },
  {
    prop:'buildingNum',
    label:'楼宇数量（栋）'
  },
  {
    prop:'totalArea',
    label:'总建筑面积（㎡）'
  }
]