import axios from "axios";
import { getToken } from '@/utils/tools'
import { computed } from "vue";
import { baseUrl } from "@/main";
import { operateInProcess } from "@/components/operateInProcess/operateInProcess";
let params = {}
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,
    showConfirm,
    conditionQuery,//条件搜索触发事件对象
    fileInput,//input文件上传dom
    inputFn,//input函数对象
    navBarTxt,
    getTableData,//获取列表
    drawerOption
){

    const basciBtn = { children: [
        {
          code: "top",
          icon: '{"name":"主要","type":"success","plain":false}',
          name: "导出全部",
          uri: "exportAll",
        },
        {
          code: "right",
          icon: '{"name":"主要","type":"primary","plain":true}',
          name: "详情",
          uri: "viewData",
        },
        {
            code: "right",
            icon: '{"name":"主要","type":"primary","plain":false}',
            name: "编辑",
            uri: "editData",
        },
        {
          code: "right",
          icon: '{"name":"危险","type":"danger","plain":false}',
          name: "删除",
          uri: "delData",
        }
    ] }
   
    store.commit("page/setCurrentMenuItem", basciBtn);
    
	//搜索
    searchOption.searchType = 'conditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '企业名称', prop: 'enterpriseName', type: 'text' },
		{ label: '楼栋名', prop: 'buildingPartName', type: 'text' },
        { label: '楼栋类型', prop: 'buildingPartType', type: 'select', options: [
            {label:'塔楼',value:0},
            {label:'裙楼',value:1},
        ]},
        
    ]
   
    tableRequestOption.params = {
    	...JSON.parse(route.query.extra)
    }
    
    tableRequestOption.url = '/sys/marketMain/page'
    
    //删除参数
    tableDelDataOption.url = '/sys/marketMain/delete'
    tableDelDataOption.type='bodyArr'
    
    handleEventObj.exportAll = ()=>{
        axios({
            data: {...JSON.parse(route.query.extra)},
            method: 'post',
            url: baseUrl + `/sys/marketMain/export`,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'yk_wx_admin': getToken("token")
            },
            responseType: 'blob',
            dataType: 'json'
        }).then(res => {
            
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
            const fileName = '重点企业列表.xls';
            const elink = document.createElement('a');
            if ('download' in elink) {
                // 非IE下载
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        });
    }

    
}
