export const tableConfig=[
    {
    	prop:'packageName',
    	label:'套餐名称'
    },
    {
    	prop:'memberConditionList',
    	label:'绑定特权字段',
        type:"tags",
        key:"filedValueDescribe",
        closable:true
    },
    {
    	prop:'status',
    	label:'状态',
        type:'state',
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1},
        ]
    },
]

export const formConfig=[
    {
    	prop:'packageName',
    	label:'套餐名称'
    },
    {
    	prop:'status',
    	label:'状态',
        type:'select',
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1},
        ]
    },
]