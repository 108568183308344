export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,
    showConfirm,
    conditionQuery,//条件搜索触发事件对象
    fileInput,//input文件上传dom
    inputFn,//input函数对象
    navBarTxt ,//按钮配置
    getTableData,
    drawerOption
){
	//搜索
    searchOption.hasSearch = false
  
    //设置新建表单默认值
    formDialogOption.defaultItem = {
		status:1
	}
    tableRequestOption.url = '/sys/member/page'
    
    //删除参数
    // tableDelDataOption.method = 'get'
    tableDelDataOption.url = '/sys/member/delete'
    tableDelDataOption.type='bodyArr'

    //套餐解绑
    customBtnEvent.tagsClose = (tag,row,prop)=>{
        showConfirm('是否解绑该套餐？',{confirm:async ()=>{
            await _axios('post',`/sys/memberPackageRelation/doUnBind`,{
                memberId:row.id
            },true)
            showToast.success('该套餐已解绑')
            customBtnEvent.refreshList()
        }})
    } 

    // 绑定套餐
    handleEventObj.bindPackage = (item)=>{//钻取下级通用列表传参配置
        if(item.memberPackage && item.memberPackage.length) return showToast.warning('该会员已绑定套餐！')
        drawerOption.show=true
        drawerOption.title="会员--绑定套餐"
        drawerOption.item = item
        drawerOption.customCopName='memberBindPackage'
    }
}
