
export const tableColumnConfig=[
    {
        prop:'name',
        label:'大楼名称',
        width:350
    },
    {
        prop: "createTime",
        label: "创建时间"
    }
]
