export const tableConfig=[
    {
    	prop:'fieldName',
    	label:'字段名'
    },
    {
    	prop:'filedNameDescribe',
    	label:'字段名描述'
    },
    {
    	prop:'fieldValue',
    	label:'特权值'
    },
    {
    	prop:'filedValueDescribe',
    	label:'特权值描述'
    }
]

export const formConfig=[
    {
    	prop:'fieldName',
    	label:'字段名',
        type:"select",
        option:[
            {label:'年份',value:'year'},
            {label:'省',value:'province_id'},
            {label:'市',value:'city_id'},
            {label:'区',value:'area_id'},
            {label:'街道',value:'parent_id'}
        ]
    }
]