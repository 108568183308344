export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    //搜索
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'标题/年份/区域',prop:'name',type:'text'},
        {label:'级别',prop:'level',type:'select',options:[
            {label:"省",value:1},
            {label:"市",value:2},
            {label:"区",value:3},
            {label:"街道",value:4},
        ]}
    ]
    
    tableRequestOption.url = '/sys/leaderModel/page' 

    //删除参数
    tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/leaderModel/delete'
    tableDelDataOption.type = 'bodyArr'

    // handleEventObj.newData = ()=>{
    //     formDialogOption.formTitle = '新建--形象展示'
    //     formDialogOption.openType = 'custom'
    //     formDialogOption.item = {}
    //     formDialogOption.showFormDialog = true
    //     formDialogOption.customCopName = 'figureShow'
    // }
    // handleEventObj.editData = (item)=>{
    //     formDialogOption.formTitle = '新建--形象展示'
    //     formDialogOption.openType = 'custom'
    //     formDialogOption.item = item
    //     formDialogOption.showFormDialog = true
    //     formDialogOption.customCopName = 'figureShow'
    // }
}

