export const tableConfig=[
    {
    	prop:'memberName',
    	label:'会员名称'
    },
    {
    	prop:'dayNum',
    	label:'时长(天)'
    },
    {
    	prop:'amount',
    	label:'充值金额',
    },
    // {
    // 	prop:'beginTime',
    // 	label:'开始日期',
    // },
    {
    	prop:'memberPackage',
    	label:'绑定套餐',
        type:"tags",
        key:"packageName",
        closable:true
    },
    {
    	prop:'status',
    	label:'状态',
        type:'state',
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1},
        ]
    },
    {
    	prop:'createTime',
    	label:'创建日期',
    },
]

export const formConfig=[
    {
    	prop:'memberName',
    	label:'会员名称'
    },
    {
    	prop:'dayNum',
    	label:'时长(天)',
        type:'number'
    },
    {
    	prop:'amount',
    	label:'充值金额',
        type:'number'
    },
    // {
    // 	prop:'beginTime',
    // 	label:'开始日期',
    //     type:'datetime'
    // },
    // {
    // 	prop:'endTime',
    // 	label:'结束日期',
    //     type:'datetime'
    // },
    {
    	prop:'status',
    	label:'状态',
        type:'select',
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1},
        ]
    },
]