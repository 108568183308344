export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
){
    //搜索
    searchOption.searchType = 'conditionQuery'
	searchOptionsConditionQuery.options = [
        {label:'会员等级名称',prop:'levelName',type:'text'},
        {label:'是否启用会员价',prop:'memberPriceStatus',type:'select',options:[
            {label:"开启",value:1},
            {label:"不开启",value:0},
        ]},
        // {label:'会员类型',prop:'memberType',type:'select',options:[
        //     {label:"用户",value:1},
        //     {label:"渠道商",value:2},
        // ]}, 
        {label:'状态',prop:'status',type:'select',options:[
            {label:"禁用",value:0},
            {label:"正常",value:1},
        ]}, 
    ]
    

    //设置新建表单默认值
    formDialogOption.defaultItem = {integral:1,memberPriceStatus:0,memberType:1,status:1}
    formDialogOption.dialogWidth = "40%"

    tableRequestOption.url = '/sys/userLevel/page'
    tableRequestOption.params = {memberType:1}

    //删除参数
    tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/userLevel/remove'
    tableDelDataOption.valkey = 'levelId'
    tableDelDataOption.type = 'bodyArr'
}

