export const tableConfig=[
    {
    	prop:'buildingPartName',
    	label:'楼栋名称'
    },
    {
    	prop:'year',
    	label:'年份'
    },
    {
    	prop:'provinceRatal',
    	label:'省级税收(元)',
    },
    {
    	prop:'cityRatal',
    	label:'市税收(元)',
    },
    {
    	prop:'areaRatal',
    	label:'区级税收(元)',
    },

]

export const formConfig=[
    {
    	prop:'provinceRatal',
    	label:'省级税收(元)',
        type:'number'
    },
    {
    	prop:'cityRatal',
    	label:'市税收(元)',
        type:'number'
    },
    {
    	prop:'areaRatal',
    	label:'区级税收(元)',
        type:'number'
    },
]