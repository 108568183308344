export const tableConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "type",
        label: "类型",
        type:'state',
        option:[
            {label:"图片",value:1},
            {label:"视频",value:2},
        ]
    },
    {
        prop: "level",
        label: "级别",
        type:'state',
        option:[
            {label:"省",value:1},
            {label:"市",value:2},
            {label:"区",value:3},
            {label:"街道",value:4},
        ]
    },
    {
        prop: "year",
        label: "年份",
    },
    {
        prop: "areaName",
        label: "区域名称",
    },
    {
        prop: "url",
        label: "图片",
        type:'img'
    },
    {
        prop: "videoUrl",
        label: "视频",
        type:'video'
    },
    {
        prop: "status",
        label: "状态",
        type:'state',
        option:[
            {label:"禁用",value:0},
            {label:"启用",value:1},
        ]
    }
]

export const formConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "type",
        label: "类型",
        type:'select',
        option:[
            {label:"图片",value:1},
            {label:"视频",value:2},
        ]
    },
    {
        prop: "year",
        label: "年份",
    },
    {
        prop: "url",
        label: "图片",
        type:'img',
        required:false,
        tips:'尺寸：335x152，格式：jpg/png'
    },
    {
        prop: "videoUrl",
        label: "视频",
        type:'file',
        limit:1,
        required:false
    },
    {
        prop: "status",
        label: "状态",
        type:'select',
        option:[
            {label:"禁用",value:0},
            {label:"启用",value:1},
        ]
    }
]