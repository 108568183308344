
export const tableColumnConfig=[
    {
        prop:'user',
        label:'核验人姓名'
    },
    {
        prop: "userPhone",
        label: "联系电话",
    },
    {
        prop: "type",
        label: "处理状态",
        type:"state",
        option:[
            {label:"待办",value:0},
            {label:"已核验",value:1}
        ]
    },
    // {
    //     prop: "province",
    //     label: "省份", 
    // },
    // {
    //     prop: "base",
    //     label: "省份缩写", 
    // },
    // {
    //     prop: "city",
    //     label: "城市", 
    // },
    // {
    //     prop: "county",
    //     label: "区/县", 
    // },
    {
        prop: "createTime",
        label: "创建时间"
    }
]
