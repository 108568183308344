export default function(
    store,route,router,
    tableColumnConfig,
    formDialogOption,
    searchParams,
    searchOption,
    searchOptionsFuzzyQuery,
    searchOptionsConditionQuery,
    pageParams,
    tableRequestOption,
    tableOption,
    tableDelDataOption,
    handleEventObj,
    customBtnEvent,
    _axios,
    showToast,
    showConfirm,
    conditionQuery,//搜索栏自定义选择数据
    fileInput,//上传文件的dom对象
    inputFn,//input
    navBarTxt//
    
){
	formDialogOption.dialogWidth = '40%'
	searchOptionsFuzzyQuery.val = {
	    keys:'num',
	    placeholder:'楼栋名称'
	}
    handleEventObj.entering = (item)=>{
        handleEventObj.resetFormDialogWidth()
        formDialogOption.formTitle = navBarTxt.subMenuItem.value.name+'税收录入'
        formDialogOption.openType = 'edit'
        formDialogOption.item = item
        formDialogOption.showFormDialog = true
    }
	//搜索
  //   searchOption.searchType = 'conditionQuery'
  //   searchOptionsConditionQuery.options = [
  //       { label: '名称', prop: 'name', type: 'text'},
		// { label: '年份', prop: 'buildTime', type: 'year' },
  //       { label: '省', prop: 'provinceId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
  //       { label: '市', prop: 'cityId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
  //       { label: '区/县', prop: 'areaId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
  //       { label: '街道办', prop: 'streetId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
  //   ]
  
    //设置新建表单默认值
    formDialogOption.defaultItem = {
		type:1,
		isSystemAir:1,
		isIntelligentize:1,
		isCenterAir:1,
		isNonMotor:1,
		flexibility:1,
		isPartyMasses:1,
		isCanteen:1,
		isExpressCounters:1,
		isSupermarket:1,
		...JSON.parse(route.query.extra)
	}
    tableRequestOption.url = '/sys/buildingPart/page'
    tableRequestOption.params = {
		...JSON.parse(route.query.extra)
	}
    //删除参数
    tableDelDataOption.method = 'get'
    tableDelDataOption.url = '/sys/buildingPart/delete'
    // tableDelDataOption.valkey='dictId'

   
}
