export const tableConfig=[
    {
        prop:'year',
		label:'年份'
    },
    {
        prop:'province',
		label:'省'
    },
    {
        prop:'city',
		label:'市'
    },
    {
        prop:'area',
		label:'区/县'
    },
    {
        prop:'street',
		label:'街道'
    },
	{
		prop:'buildingName',
		label:'楼宇名'
	},
	{
		prop:'buildingPartName',
		label:'楼栋名'
	},
    {
		prop:'provinceRatal',
		label:'省级税收'
	},
    {
		prop:'cityRatal',
		label:'市级税收'
	},
    {
		prop:'areaRatal',
		label:'区级税收'
	},
    {
		prop:'totalRatal',
		label:'总税收'
	},
]

export const formConfig=[
    {
		prop:'provinceRatal',
		label:'省级税收',
        type:"number"
	},
    {
		prop:'cityRatal',
		label:'市级税收',
        type:"number"
	},
    {
		prop:'areaRatal',
		label:'区级税收',
        type:"number"
	},
]