import { computed,createApp } from "vue"
import enterpriseList from './enterpriseList.vue'
export default function(
  store,route,router,
  tableColumnConfig,//表格表头
  formDialogOption,//表单弹窗相关配置
  searchParams,//搜索条件
  searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
  searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
  searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
  pageParams,//页面相关配置
  tableRequestOption,//表格获取数据相关配置
  tableOption,//表格属性配置
  tableDelDataOption,//数据删除相关配置
  handleEventObj,//顶部及右侧操作按钮事件
  customBtnEvent,//自定义事件
  _axios,//网络请求
  showToast,
  showConfirm,
  conditionQuery,//条件搜索触发事件对象
  fileInput,//input文件上传dom
  inputFn,//input函数对象
){
    //多选
    tableOption.selection = true

    const subMenuItem = computed(() => store.state.page.subMenuItem)
    const basciBtn = { children: [] }
    const buildingBtn = { children: [] }
    subMenuItem.value.children.map(v => {
        if (v.permission == 20) {
            basciBtn.children.push(v)
        }
    })
    store.commit("page/setCurrentMenuItem", basciBtn);

	formDialogOption.dialogWidth = '40%'
	searchOptionsFuzzyQuery.val = {
	    keys:'num',
	    placeholder:'楼层'
	}

    //设置新建表单默认值
    formDialogOption.defaultItem = {
		...JSON.parse(route.query.extra)
	}
	
    tableRequestOption.url = '/sys/buildingFloor/page'
    tableRequestOption.params = {
    	...JSON.parse(route.query.extra)
    }
	
    //删除参数
    tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/buildingFloor/delete'
    tableDelDataOption.type='bodyArr'


    let divEl;
    let dom;
    const setDivStyle = () => {
        divEl.style.height = '100vh'
        divEl.style.width = '100vw'
        divEl.style.position = 'fixed'
        divEl.style.top = 0
        divEl.style.left = 0
        divEl.style.zIndex = 99
    }
    function removeDivle() {
        dom.unmount(divEl)
        document.body.removeChild(divEl)
    }
    handleEventObj.binding = (item) => {
        divEl = document.createElement('div')
        dom = createApp(enterpriseList, {
            removeDivle,
            item
        })
        setDivStyle()
        document.body.appendChild(divEl)
        dom.mount(divEl)
    }
   
}
