import axios from "axios";
import { getToken } from '@/utils/tools'
import { computed } from "vue";
import { baseUrl } from "@/main";
import { operateInProcess } from "@/components/operateInProcess/operateInProcess";
let params = {}
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,
    showConfirm,
    conditionQuery,//条件搜索触发事件对象
    fileInput,//input文件上传dom
    inputFn,//input函数对象
    navBarTxt,
    getTableData,//获取列表
    drawerOption
){
    
	//搜索
    searchOption.searchType = 'conditionQuery'
    searchOption.open = true
    searchOptionsConditionQuery.options = [
		{ label: '年份', prop: 'year', type: 'year' },
        { label: '省', prop: 'provinceId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '市', prop: 'cityId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '区/县', prop: 'areaId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '街道办', prop: 'streetId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '楼宇', prop: 'buildingName', type: 'text'},
        { label: '楼栋', prop: 'buildingPartName', type: 'text'},
    ]
  
    conditionQuery.getSelectVal = (e)=>{ //获取省市区街道
        const [sitem, item] = e
        if (item.prop == 'provinceId') {
            getProvince(sitem.areaId, 'province')
        }
        if (item.prop == 'cityId') {
            getProvince(sitem.areaId, 'city')
        }
        if (item.prop == 'areaId') {
            getProvince(sitem.areaId, 'area')
        }
		if (item.prop == 'streetId') {
		   
		}
    }

    handleEventObj.reset=()=>{
        params = {}
        // searchOptionsConditionQuery.options[1].options = []
        searchOptionsConditionQuery.options[2].options = []
        searchOptionsConditionQuery.options[3].options = []
        searchOptionsConditionQuery.options[4].options = []
    }
    handleEventObj.searchOpt = (item)=>{
        params = item
    }
   

    
    tableRequestOption.url = '/sys/ratal/page'
    
    //删除参数
    // tableDelDataOption.method = 'get'
    // tableDelDataOption.url = '/sys/buildingDetail/delete'
    // tableDelDataOption.valkey='dictId'

    // 楼栋
    // handleEventObj.building = (item)=>{//钻取下级通用列表传参配置
    //     router.push({
    //         path:`/buildingSingle/buildingInfo`,
    //         query:{
	// 			icon:'back',//返回
	// 			title:'楼栋',//顶部左侧标题
	// 			extra:JSON.stringify({buildingId:item.id}),//其他额外携带参数,有的话再传
    //         }
    //     })
    // }

    // 导出税收
    handleEventObj.exportTaxinfo = ()=>{
        operateInProcess({show:true,title:'正在导出···'})
        axios({
            data: {...params},
            method: 'post',
            url: baseUrl + `/sys/ratal/exportRatal`,
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'yk_wx_admin': getToken("token")
            },
            responseType: 'blob',
            dataType: 'json'
        }).then(res => {
            // 
            operateInProcess({show:false})
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
            const fileName = '税收数据表.xls';
            const elink = document.createElement('a');
            if ('download' in elink) {
                // 非IE下载
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob);
            }
        }).catch(err=>{
            operateInProcess({show:false})
            showToast.error('导出模板出错')
        });
    }
	
	// 获取省份
    getProvince()
	async function getProvince(id = 1, type = '') {
	    const res = await _axios('get', `/sys/area/listByPid?pid=${id}`, {}, true)
	    if (type == 'province') {
	       searchOptionsConditionQuery.options[2].options = res
	    } else if (type == 'city') {
	       searchOptionsConditionQuery.options[3].options = res
	    } else if (type == 'area') {
	       searchOptionsConditionQuery.options[4].options = res
	    } else {
	       searchOptionsConditionQuery.options[1].options = res
	    }
	}
}
