export const tableConfig=[
    {
        prop:'dictName',
        label:'字典名称	'
    },
    {
        prop:'dictType',
        label:'字典类型'
    },
    {
        prop:'status',
        label:'状态',
        type:'status',
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1},
        ]
    },
    {
        prop:'createBy',
        label:'创建者'
    },
    {
        prop:'createTime',
        label:'创建时间'
    },
    {
        prop:'remark',
        label:'备注'
    },
]

export const formConfig=[
    {
        prop:'dictName',
        label:'字典名称	'
    },
    {
        prop:'dictType',
        label:'字典类型',
        explain:''//编写说明
    },
    {
        prop:'status',
        label:'状态',
        type:"select",
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1},
        ]
    },
    {
        prop:'remark',
        label:'备注',
        type:'textarea'
    },
]