export const tableConfig=[
    {
        prop: "buildingPartName",
        label: "楼栋名",
    },
    {
        prop: "buildingPartType",
        label: "楼栋类型",
        type:'state',
        disabled:true,
        option:[
            {label:'塔楼',value:0},
            {label:'裙楼',value:1},
        ]
    },
    {
        prop: "title",
        label: "标题",
    }
]


export const formConfig=[
    {
        prop: "buildingPartId",
        label: "楼栋",
        type:'select',
        option:[]
    },
    {
        prop: "title",
        label: "标题",
    },
    {
        prop: "content",
        label: "内容",
        type:'editor'
    }
]

// export const editFormConfig=[
//     {
//         prop: "title",
//         label: "标题",
//     },
//     {
//         prop: "content",
//         label: "内容",
//     }
// ]

