
export const tableConfig= [{
			prop: "username",
			label: "用户名",
			// type: "text", //配置内容显示格式
		},
		{
			prop: "operation",
			label: "用户操作",
		},
		{
			prop: "createDate",
			label: "创建时间",
		},
		{
			prop: "time",
			label: "执行时长",
		},
		{
			prop: "type",
			label: "日志类型",
		}, {
			prop: "method",
			label: "请求方法",
		}, {
			prop: "params",
			label: "请求参数",
		},
	]

