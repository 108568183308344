export const tableConfig = [
	{
		prop: 'name',
		label: '楼栋名称'
	},
	{
		prop: 'sort',
		label: '楼栋顺序'
	},
	
	{
		prop: 'areaEachFloor',
		label: '单层面积(㎡)',
		type:'number'
	},
	// {
	// 	prop: 'signalUnusedArea',
	// 	label: '单层空置面积(㎡)',
	// 	type: 'number'
	// },
	{
		prop: 'unusedArea',
		label: '空置面积(㎡)',
		type:'number'
	},
	{
		prop: 'type',
		label: '楼栋类型',
		type: "state",
		option: [{
				label: '塔楼',
				value: 0
			},
			{
				label: '裙楼',
				value: 1
			},
		]
	},
	{
		prop: 'floorNum',
		label: '地上层数',
		type:'number'
	},
	// {
	// 	prop: 'provinceRatal',
	// 	label: '省纳税额'
	// },
	// {
	// 	prop: 'cityRatal',
	// 	label: '市纳税额'
	// },
	// {
	// 	prop: 'areaRatal',
	// 	label: '区纳税额'
	// },
	// {
	// 	prop: 'ratalYear',
	// 	label: '纳税年份',
	// },
	
	// {
	// 	prop: 'partArea',
	// 	label: '楼栋占地面积'
	// },
	{
		prop: 'partArea',
		label: '建筑面积（㎡）'
	},
	// {
	// 	prop:'buildTime',
	// 	label:'建成（改造）年份'
	// },
	{
		prop:'managementCompany',
		label:'物业公司名称'
	},
	{
		prop:'managementContact',
		label:'物业联系人'
	},
	{
		prop:'managementContactPhone',
		label:'物业联系人电话'
	},
]

export const formConfig = [
	{
		prop: 'name',
		label: '楼栋名称'
	},
	{
		prop: 'typeBusiness',
		label: '主要业态',
		type:'select',
		option:[
			{label:'商务楼',value:'businessBuilding'},
			{label:'商住楼',value:'commercialBuilding'},
			{label:'商贸楼',value:'trade'},
			{label:'商业',value:'business'},
			{label:'企业独立办公',value:'IndependentOffice'},
			{label:'产业园',value:'culturalCreation'},
		]
	},
	{
    	prop:'type',
    	label:'楼栋类型',
		type:'radio',
		option:[
			{label:'塔楼',value:0},
			{label:'裙楼',value:1},
		]
    },
	{
		prop:'buildTime',
		label:'建成（改造）年份',
		type:'number'
	},
	{
		prop: 'areaEachFloor',
		label: '单层面积(㎡)',
		required:false,
		type:'number'
	},
	// {
	// 	prop: 'signalUnusedArea',
	// 	label: '单层空置面积(㎡)',
	// 	required:false,
	// 	type: 'number'
	// },
	{
		prop: 'unusedArea',
		label: '空置面积(㎡)',
		required:false,
		type:'number'
	},
	{
		prop: 'floorNum',
		label: '地上层数',
		required:false,
		type:'number'
	},
	{
		prop: 'floorNumUnder',
		label: '地下层数',
		required:false,
		type:'number'
	},
	
	{
		prop: 'sort',
		label: '顺序',
		required:false,
		type:'number'
	},	
	{
		prop: 'provinceRatal',
		label: '省级税收',
		required:false,
		type:'number'
	},
	{
		prop: 'cityRatal',
		label: '市级税收',
		required:false,
		type:'number'
	},
	{
		prop: 'areaRatal',
		label: '区级税收',
		required:false,
		type:'number'
	},
	// {
	// 	prop: 'ratalYear',
	// 	label: '纳税年份',
	// 	required:false,
	// 	type:'number'
	// },
	// {
	// 	prop: 'partArea',
	// 	label: '楼栋占地面积m²',
	// 	required:false,
	// 	type:'number'
	// },	
	{
		prop: 'partArea',
		label: '建筑面积（㎡）',
		required:false,
		type:'number'
	},	

	{
		prop: 'cargoLiftNum',
		label: '货梯数',
		required:false,
		type:'number'
	},
	{
		prop: 'passengerLiftNum',
		label: '客梯数',
		required:false,
		type:'number'
	},
	{
		prop: 'stairsNum',
		label: '楼梯数',
		required:false,
		type:'number'
	},
	// {
	// 	prop: 'firstArea',
	// 	label: '首层占地面积m²',
	// 	required:false,
	// 	type:'number'
	// },
	{
		prop: 'floorHeight',
		label: '层高',
		required:false,
		type:'number'
	},
	// {
    // 	prop:'isInformationPublish',
    // 	label:'是否有信息发布平台',
	// 	type:'radio',
	// 	required:false,
	// 	option:[
	// 		{label:'是',value:1},
	// 		{label:'否',value:0},
	// 	]
    // },
	// {
	// 	prop:'isCenterAir',
	// 	label:'有无中央空调',
	// 	type:'radio',
	// 	required:false,
	// 	option:[
	// 		{label:'是',value:1},
	// 		{label:'否',value:0},
	// 	]
	// },
	// {
	// 	prop:'flexibility',
	// 	label:'空间灵活性与拓展性',
	// 	type:'radio',
	// 	required:false,
	// 	option:[
	// 		{label:'布局局限性大，改造难度较大',value:1},
	// 		{label:'布置灵活有改造和扩展条件',value:0},
	// 	]
	// },
	{
		prop:'isPartyMasses',
		label:'是否进行党群建设',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
	},
	{
		prop:'isCanteen',
		label:'是否设有楼宇餐厅',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
	},
	{
		prop:'isExpressCounters',
		label:'是否有快递专柜',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
	},
	{
		prop:'isSupermarket',
		label:'是否有生活超市',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
	},
	{
    	prop:'isCenterAir',
    	label:'是否有中央空调配置',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
    },
	// {
	// 	prop:'isIntelligentize',
	// 	label:'是否有智能化配置',
	// 	type:'radio',
	// 	required:false,
	// 	option:[
	// 		{label:'是',value:1},
	// 		{label:'否',value:0},
	// 	]
	// },
	{
		prop:'isSystemAir',
		label:'是否有新风系统',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
	},
	{
		prop: 'lobbyArea',
		label: '大堂面积m²',
		required:false,
		type:'number'
	},
	{
		prop: 'lobbyHeight',
		label: '大堂层高',
		required:false,
		type:'number'
	},
	
	
	{
		prop: 'propertyFee',
		label: '物业费',
		required:false,
		type:'number'
	},
	{
		prop: 'rentalPrice',
		label: '租赁价格(元/月/㎡)',
		required:false,
		type:'number'
	},
	{
		prop: 'salePrice',
		label: '销售价格',
		required:false,
		type:'number'
	},
	
	
	// {
	// 	prop: 'upperNum',
	// 	label: '地上层数',
	// 	required:false,
	// 	type:'number'
	// },
	
	{
		prop: 'standardId',
		label: '交付标准',
		required:false,
		// type:'select'
	},

]
