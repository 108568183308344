export default function(
    store,route,router,
    formColumnConfig,//表单label相关配置
    formOption,//表单配置
    clickBtnSelectObj,//自定义点击类型按钮配置
    fromHndleEventObj,
    _axios,
    showToast,
    showConfirm
){
    formOption.nextStep = 'all'
    // formOption.newApiOption.api = `/leaderModel/addOrModify`
    // formOption.updateApiOption.api = `/sys/yunke/mgr/updateAdminInfo`
    fromHndleEventObj.myCustomEvent = (formData)=>{
        clickBtnSelectObj.clickBtnShowCom = true
        clickBtnSelectObj.title = '模块--设置'
        clickBtnSelectObj.size = '40%'
        clickBtnSelectObj.cpname = 'leaderModelSet'
    }
}
