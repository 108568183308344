import { operateInProcess } from "@/components/operateInProcess/operateInProcess";
import { baseUrl } from "@/main";
import { getToken } from "@/utils/tools";
import axios from "axios";

export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,//弹窗
    showConfirm,
    conditionQuery,//搜索栏自定义选择数据
    fileInput,//上传文件的dom对象
    inputFn,//input
    navBarTxt ,//按钮配置
    getTableData,
    drawerOption
){
    //多选
    tableOption.selection = true
    //搜索
    searchOption.searchType = 'conditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '企业名称', prop: 'enterpriseName', type: 'text'},
		{ label: '数据年份', prop: 'dataYear', type: 'year' },
        // { label: '迁入迁出', prop: 'isIn', type: 'select', options: [
        //     {label:'迁入',value:1},
        //     {label:'迁出',value:2}
        // ]},
        // { label: '登记类别', prop: 'registerType', type: 'select', options: [
        //     {label:'内资',value:1},
        //     {label:'外资',value:2}
        // ]},
        // { label: '市', prop: 'cityId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        // { label: '区/县', prop: 'areaId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        // { label: '街道办', prop: 'streetId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
    ]




    tableRequestOption.url = '/sys/headquarterEnterprise/page'
    
    //删除参数
    tableDelDataOption.url = "/sys/headquarterEnterprise/delete";
    tableDelDataOption.type = 'bodyArr'
    tableDelDataOption.methodBatch = 'delete'
    


    handleEventObj.file = async (e)=>{
        fileInput.value.click()
    }
    
    handleEventObj.export = ()=>{
       axios({
            data: {},
            method: 'get',
            url: baseUrl + `/sys/headquarterEnterprise/template`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'yk_wx_admin': getToken("token")
            },
            responseType: 'blob',
            dataType: 'json'
        }).then(res => {
            
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
            const fileName = '总部企业模板.xls';
            const elink = document.createElement('a');
            if ('download' in elink) {
                // 非IE下载
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        });
    }
    // 导出数据
    handleEventObj.exportData = ()=>{
        operateInProcess({show:true,title:'正在导出···'})
        axios({
            data: {},
            method: 'post',
            url: baseUrl + `/sys/headquarterEnterprise/export`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'yk_wx_admin': getToken("token")
            },
            responseType: 'blob',
            dataType: 'json'
        }).then(res => {
            operateInProcess({show:false})
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
            const fileName = '总部企业表.xls';
            const elink = document.createElement('a');
            if ('download' in elink) {
                // 非IE下载
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        }).catch(_=>{
            operateInProcess({show:false})
            showToast.error('导出失败')
            
        });
    }

    inputFn.file = async (e)=>{
        let files = e.target.files || e.dataTransfer.files;
        let formData = new FormData();
        formData.append('file', files[0])
        const res =  await _axios('post','/sys/headquarterEnterprise/import',formData,true)
        showToast.success('导入成功')
        getTableData()
        // console.log(res);
    }

   
	
}
