export const tableConfig=[
    {
        prop: "deptName",
        label: "部门名称",
    },
    {
        prop: "areaName",
        label: "地区名称",
    },
    {
        prop: "level",
        label: "等级",
        type:"state",
        option:[
            // 1省 2市 3区 4街道
            {label:"省",value:1},
            {label:"市",value:2},
            {label:"区",value:3},
            {label:"街道",value:4},
        ]
    },
    {
        prop: "isFilter",
        label: "是否过滤",
        type:"state",
        option:[{label:"不过滤",value:0},{label:"过滤",value:1}]
    },
    {
        prop: "status",
        label: "状态",
        type:"state",
        option:[{label:"禁用",value:0},{label:"启用",value:1}]
    }
]

export const formConfig=[
    {
        prop: "deptName",
        label: "部门名称",
    },
    // {
    //     prop: "areaName",
    //     label: "地区名称",
    // },
    // {
    //     prop: "level",
    //     label: "等级",
    //     type:"select",
    //     option:[
    //         // 1省 2市 3区 4街道
    //         {label:"省",value:1},
    //         {label:"市",value:2},
    //         {label:"区",value:3},
    //         {label:"街道",value:4},
    //     ]
    // },
    {
        prop: "isFilter",
        label: "是否过滤",
        type:"select",
        option:[{label:"不过滤",value:0},{label:"过滤",value:1}]
    },
    {
        prop: "status",
        label: "状态",
        type:"select",
        option:[{label:"禁用",value:0},{label:"启用",value:1}]
    }
]