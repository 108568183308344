
export const tableColumnConfig=[
    {
        prop:'areaName',
        label:'名称',
        width:350
    },
    {
        prop: "areaId",
        label: "唯一编码",
        width:350
    },
    // {
    //     prop: "createTime",
    //     label: "创建时间"
    // }
]
