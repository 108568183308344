import axios from "axios";
import { getToken } from '@/utils/tools'
import { computed } from "vue";
import { baseUrl } from "@/main";
import { operateInProcess } from "@/components/operateInProcess/operateInProcess";
export default function(
    store,route,router,
    tableColumnConfig,//表格表头
    formDialogOption,//表单弹窗相关配置
    searchParams,//搜索条件
    searchOption,//顶部搜索配置   searchType为fuzzyQuery conditionQuery
    searchOptionsFuzzyQuery,//模糊搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    searchOptionsConditionQuery,//条件搜索配置 searchType为fuzzyQuery为obj / searchType为conditionQuery为array
    pageParams,//页面相关配置
    tableRequestOption,//表格获取数据相关配置
    tableOption,//表格属性配置
    tableDelDataOption,//数据删除相关配置
    handleEventObj,//顶部及右侧操作按钮事件
    customBtnEvent,//自定义事件
    _axios,//网络请求
    showToast,
    showConfirm,
    conditionQuery,//条件搜索触发事件对象
    fileInput,//input文件上传dom
    inputFn,//input函数对象
    navBarTxt,
    getTableData,//获取列表
    drawerOption
){

    const subMenuItem = computed(() => store.state.page.subMenuItem)
    const basciBtn = { children: [] }
    const buildingBtn = { children: [] }
    subMenuItem.value.children.map(v => {
        if (v.permission == 2) {
            basciBtn.children.push(v)
        }
    })
    store.commit("page/setCurrentMenuItem", basciBtn);

    //多选
    tableOption.selection = true

    tableOption.customBack = true

	//搜索
    searchOption.searchType = 'conditionQuery'
    searchOptionsConditionQuery.options = [
        { label: '名称', prop: 'name', type: 'text'},
		{ label: '年份', prop: 'year', type: 'year' },
        { label: '省', prop: 'provinceId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '市', prop: 'cityId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '区/县', prop: 'areaId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '街道办', prop: 'streetId', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
    ]
  
    conditionQuery.getSelectVal = (e)=>{ //获取省市区街道
        const [sitem, item] = e
        if (item.prop == 'provinceId') {
            getProvince(sitem.areaId, 'province')
        }
        if (item.prop == 'cityId') {
            getProvince(sitem.areaId, 'city')
        }
        if (item.prop == 'areaId') {
            getProvince(sitem.areaId, 'area')
        }
		if (item.prop == 'streetId') {
		   
		}
    }
   
    
    tableRequestOption.url = '/sys/buildingMajor/page'
    
    //删除参数
    tableDelDataOption.method = 'post'
    tableDelDataOption.url = '/sys/buildingMajor/delete'
    tableDelDataOption.tips= "是否删除选中的所有数据？"
    tableDelDataOption.type = "bodyArr"
    // tableDelDataOption.valkey='dictId'

    //添加重点楼宇
    handleEventObj.newData = ()=>{ 
        // drawerOption.show = true
        // drawerOption.direction = 'ttb'
        // drawerOption.title = '重点楼宇筛选'
        // drawerOption.size = '100%'
        // drawerOption.customCopName = 'addImportantBuilding'
        router.push({
            path:`/addImportantBuilding`,
            query:{
				icon:'back',//返回
				title:'重点楼宇筛选',//顶部左侧标题
            }
        })
        
    }

    // 导入重点企业
    handleEventObj.importImpEp = ()=>{
        fileInput.value.click()
    }

    inputFn.file = async (e)=>{
        let files = e.target.files || e.dataTransfer.files;
        let formData = new FormData();
        formData.append('file', files[0])
        const res =  await _axios('post','/sys/marketMain/upload',formData,true)
        showToast.success('导入成功')
        getTableData()
        // console.log(res);
    }

    // 导出重点企业模板
    handleEventObj.exportEpTpl = ()=>{
        axios({
            data: {},
            method: 'get',
            url: baseUrl + `/sys/marketMain/download`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'yk_wx_admin': getToken("token")
            },
            responseType: 'blob',
            dataType: 'json'
        }).then(res => {
            
            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
            const fileName = '重点楼宇下设楼栋重点企业模板.xls';
            const elink = document.createElement('a');
            if ('download' in elink) {
                // 非IE下载
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        });
    }

    //重点企业
    handleEventObj.importantEp = (item)=>{
        router.push({
            path:`/building/impEp`,
            query:{
				icon:'back',//返回
				title:'重点企业',//顶部左侧标题
				extra:JSON.stringify({buildingId:item.id}),//其他额外携带参数,有的话再传
            }
        })
    }

    //重点产业
    handleEventObj.importantCy = (item)=>{
        router.push({
            path:`/building/impCy`,
            query:{
				icon:'back',//返回
				title:'重点产业',//顶部左侧标题
				extra:JSON.stringify({buildingId:item.id}),//其他额外携带参数,有的话再传
            }
        })
    }

	
	// 获取省份
    getProvince()
	async function getProvince(id = 1, type = '') {
	    const res = await _axios('get', `/sys/area/listByPid?pid=${id}`, {}, true)
	    if (type == 'province') {
	       searchOptionsConditionQuery.options[3].options = res
	    } else if (type == 'city') {
	       searchOptionsConditionQuery.options[4].options = res
	    } else if (type == 'area') {
	       searchOptionsConditionQuery.options[5].options = res
	    } else {
	       searchOptionsConditionQuery.options[2].options = res
	    }
	}
}
