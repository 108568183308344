export const tableConfig=[
    {
        prop: "buildingPartName",
        label: "楼栋名",
    },
    {	
        prop: "type",
        label: "楼栋类型",
        type:'state',
        option:[
            {label:'塔楼',value:0},
            {label:'裙楼',value:1},
        ]
    },
    {
        prop: "name",
        label: "企业名称",
    },
    {	
        prop: "businessTerm",
        label: "营业期限",
    },
    {	
        prop: "checkDate",
        label: "核准日期",
    },
    {											
        prop: "year",
        label: "重点企业年份",
    },
    {	
        prop: "companyType",
        label: "企业类型",
    },
    // {	
    //     prop: "contributedCapital",
    //     label: "实缴资本(万)",
    // },
    // {	
    //     prop: "contributedCapitalUnit",
    //     label: "实缴资本单位",
    // },
    {	
        prop: "dateEstablish",
        label: "成立日期",
    },
    {	
        prop: "email",
        label: "邮箱",
    },
    {	
        prop: "industry",
        label: "所属行业",
    },
    {	
        prop: "insuredNum",
        label: "参保人数",
    },
    {	
        prop: "legalPerson",
        label: "法定代表人",
    },
    {	
        prop: "manageType",
        label: "经营状态",
    },
    {	
        prop: "netAddress",
        label: "网址",
    },
    {	
        prop: "oldName",
        label: "曾用名",
    },
    {	
        prop: "otherPhone",
        label: "其他电话",
    },
    {		
        prop: "phone",
        label: "联系电话",
    },
    // {			
    //     prop: "progress",
    //     label: "进度",
    // },
    {				
        prop: "province",
        label: "所属省份",
    },
    {
        prop: "area",
        label: "所属区县",
    },
    {	
        prop: "city",
        label: "所属城市",
    },
    // {					
    //     prop: "registerCode",
    //     label: "注册号",
    // },
    // {					
    //     prop: "registerFund",
    //     label: "注册资本(万)",
    // },
    // {						
    //     prop: "registerFundUnit",
    //     label: "注册资本单位",
    // },
    // {							
    //     prop: "registerType",
    //     label: "登记类别",
    // },
    {								
        prop: "revenueCode",
        label: "纳税人识别码",
    },
    {									
        prop: "unifiedCreditCode",
        label: "组织机构代码",
    },
    {										
        prop: "unifiedSocialCreditCode",
        label: "统一社会信用代码",
    },
    
]


export const formConfig=[
    {
        prop: "buildingPartName",
        label: "楼栋名",
    },
    {	
        prop: "type",
        label: "楼栋类型",
        type:'state',
        option:[
            {label:'塔楼',value:0},
            {label:'裙楼',value:1},
        ]
    },
    {
        prop: "name",
        label: "企业名称",
    },
    
    
    {	
        prop: "businessTerm",
        label: "营业期限",
    },
    {	
        prop: "checkDate",
        label: "核准日期",
    },
  
    {	
        prop: "companyType",
        label: "企业类型",
    },
    // {	
    //     prop: "contributedCapital",
    //     label: "实缴资本(万)",
    // },
    // {	
    //     prop: "contributedCapitalUnit",
    //     label: "实缴资本单位",
    // },
    {	
        prop: "dateEstablish",
        label: "成立日期",
    },
    {	
        prop: "email",
        label: "邮箱",
    },
    {	
        prop: "industry",
        label: "所属行业",
    },
    {	
        prop: "insuredNum",
        label: "参保人数",
    },
    {	
        prop: "legalPerson",
        label: "法定代表人",
    },
    {	
        prop: "manageType",
        label: "经营状态",
    },
    {	
        prop: "netAddress",
        label: "网址",
    },
    {	
        prop: "oldName",
        label: "曾用名",
    },
    {	
        prop: "otherPhone",
        label: "其他电话",
    },
    {		
        prop: "phone",
        label: "联系电话",
    },
    // {			
    //     prop: "progress",
    //     label: "进度",
    // },
    {				
        prop: "province",
        label: "所属省份",
    },
    {
        prop: "area",
        label: "所属区县",
    },
    {	
        prop: "city",
        label: "所属城市",
    },
    // {					
    //     prop: "registerCode",
    //     label: "注册号",
    // },
    // {					
    //     prop: "registerFund",
    //     label: "注册资本(万)",
    // },
    // {						
    //     prop: "registerFundUnit",
    //     label: "注册资本单位",
    // },
    // {							
    //     prop: "registerType",
    //     label: "登记类别",
    // },
    {								
        prop: "revenueCode",
        label: "纳税人识别码",
    },
    {									
        prop: "unifiedCreditCode",
        label: "组织机构代码",
    },
    {										
        prop: "unifiedSocialCreditCode",
        label: "统一社会信用代码",
    },
    {											
        prop: "year",
        label: "重点企业年份",
    },
    {	
        prop: "businessScope",
        label: "经营范围",
        type:"textarea",
    },
]

export const editFormConfig=[
    {
        prop: "name",
        label: "企业名称",
        required:false
    },
    {	
        prop: "oldName",
        label: "曾用名",
        required:false
    },
    {											
        prop: "year",
        label: "重点企业年份",
        required:false
    },
    
    {	
        prop: "businessTerm",
        label: "营业期限",
        required:false
    },
    {	
        prop: "checkDate",
        label: "核准日期",
        required:false
    },
    {	
        prop: "companyType",
        label: "企业类型",
        required:false
    },
    // {	
    //     prop: "contributedCapital",
    //     label: "实缴资本(万)",
    //     required:false
    // },
    // {	
    //     prop: "contributedCapitalUnit",
    //     label: "实缴资本单位",
    //     required:false
    // },
    {	
        prop: "dateEstablish",
        label: "成立日期",
        required:false
    },
    {	
        prop: "email",
        label: "邮箱",
        required:false
    },
    {	
        prop: "industry",
        label: "所属行业",
        required:false
    },
    {	
        prop: "insuredNum",
        label: "参保人数",
        required:false
    },
    {	
        prop: "legalPerson",
        label: "法定代表人",
        required:false
    },
    {	
        prop: "manageType",
        label: "经营状态",
        required:false
    },
    {	
        prop: "netAddress",
        label: "网址",
        required:false
    },
    
    {	
        prop: "otherPhone",
        label: "其他电话",
        required:false
    },
    {		
        prop: "phone",
        label: "联系电话",
        required:false
    },
    // {			
    //     prop: "progress",
    //     label: "进度",
    //     required:false
    // },
    {				
        prop: "province",
        label: "所属省份",
        required:false
    },
    {
        prop: "area",
        label: "所属区县",
        required:false
    },
    {	
        prop: "city",
        label: "所属城市",
        required:false
    },
    // {					
    //     prop: "registerCode",
    //     label: "注册号",
    //     required:false
    // },
    // {					
    //     prop: "registerFund",
    //     label: "注册资本(万)",
    //     required:false
    // },
    // {						
    //     prop: "registerFundUnit",
    //     label: "注册资本单位",
    //     required:false
    // },
    // {							
    //     prop: "registerType",
    //     label: "登记类别",
    //     required:false
    // },
    {								
        prop: "revenueCode",
        label: "纳税人识别码",
        required:false
    },
    {									
        prop: "unifiedCreditCode",
        label: "组织机构代码",
        required:false
    },
    {										
        prop: "unifiedSocialCreditCode",
        label: "统一社会信用代码",
        required:false
    },
    {	
        prop: "businessScope",
        label: "经营范围",
        type:"textarea",
        required:false
    }
]

