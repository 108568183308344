export const tableConfig=[
	{
		prop:'img',
		label:'楼宇图片',
		type:'img'
	},
	{
		prop:'name',
		label:'楼宇名称'
	},
	{
		prop:'year',
		label:'年份'
	},
	// {
	// 	prop:'anotherName',
	// 	label:'楼宇别名或曾用名'
	// },
	{
		prop:'provinceName',
		label:'所在省'
	},
	{
		prop:'cityName',
		label:'所在市'
	},
	{
		prop:'areaName',
		label:'所在区'
	},
	
	{
		prop:'streetName',
		label:'所在街道'
	},
	// {
	// 	prop:'buildTime',
	// 	label:'建成（改造）年份',
	// },
	{
		prop:'detailAddr',
		label:'楼宇详细地址'
	},

]

export const formConfig=[
    {
    	prop:'name',
    	label:'楼宇名称'
    },
	{
		prop:'img',
		label:'楼宇图片',
		type:'img',
		required:false
	},
	{
		prop:'year',
		label:'年份',
		type:'number'
	},
    // {
    // 	prop:'anotherName',
    // 	label:'楼宇别名或曾用名',
	// 	required:false
    // },
	// {
    // 	prop:'buildTime',
    // 	label:'建成（改造）年份',
	// 	required:false
    // },
	{
    	prop:'provinceName',
		type:'addrUnion',
    	label:['所在省','所在市','所在区','所在街道办'],
		addrId:['provinceId','cityId','areaId','streetId'],
		addrName:['provinceName','cityName','areaName','streetName'],
		required:[true,true,true,true],
    },
	{
		prop:'managementCompany',
		label:'物业公司名称',
		required:false
	},
	{
		prop:'managementContact',
		label:'物业联系人',
		required:false
	},
	{
		prop:'managementContactPhone',
		label:'物业联系人电话',
		required:false
	},
	{
    	prop:'detailAddr',
    	label:'楼宇详细地址',
		required:false
    },
	{
		prop: 'selfRate',
		label: '自持率%',
		required:false,
		type:'number'
	},
	{
		prop: 'newnessRate',
		label: '建筑外观及成新率%',
		required:false,
		type:'number'
	},
	{
    	prop:'parkUp',
    	label:'地上车位数',
		required:false,
		type:'number'
    },
	{
		prop:'parkDown',
		label:'地下车位数',
		required:false,
		type:'number'
	},
	// {
	// 	prop:'flexibility',
	// 	label:'空间灵活性与拓展性',
	// 	required:false,
	// 	type:'radio',
	// 	option:[
	// 		{label:'布置灵活，有改造和拓展条件',value:1},
	// 		{label:'布局局限性大，改造难度较大',value:0},
	// 	]
	// },
	{
		prop:'isNonMotor',
		label:'是否有非机动车车位',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
	},
	{
    	prop:'isPartyMasses',
    	label:'是否进行党群建设 ',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
    },
	{
    	prop:'isCanteen',
    	label:'是否设有楼宇餐厅 ',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
    },
	
	{
    	prop:'isExpressCounters',
    	label:'是否有快递专柜',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
    },
	// {
    // 	prop:'isIntelligentize',
    // 	label:'是否有楼宇智能化配置',
	// 	type:'radio',
	// 	required:false,
	// 	option:[
	// 		{label:'是',value:1},
	// 		{label:'否',value:0},
	// 	]
    // },
	// {
    // 	prop:'isInformationPublish',
    // 	label:'是否有信息发布平台',
	// 	type:'radio',
	// 	required:false,
	// 	option:[
	// 		{label:'是',value:1},
	// 		{label:'否',value:0},
	// 	]
    // },
	{
    	prop:'isCenterAir',
    	label:'是否有中央空调配置',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
    },
	{
    	prop:'isSystemAir',
    	label:'是否有新风系统',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
    },
	{
    	prop:'isSupermarket',
    	label:'是否设有生活超市',
		type:'radio',
		required:false,
		option:[
			{label:'是',value:1},
			{label:'否',value:0},
		]
    },
	{
		prop:'greenRate',
		label:'绿化程度',
		type:'select',
		required:false,
		option:[
			{label:'较高',value:'betterEducated'},
			{label:'高',value:'degreeHigh'},
			{label:'较低',value:'lowDegree'},
			{label:'无',value:'degreeNone'},
		]
	},
	{
		prop:'aroundSchool',
		label:'周边学校',
		required:false
	},
	{
		prop:'aroundHospital',
		label:'周边医院',
		required:false
	},
	{
		prop:'aroundHotel',
		label:'周边酒店',
		required:false
	},
	{
		prop:'aroundMetro',
		label:'周边地铁',
		required:false
	},
	{
		prop:'aroundBus',
		label:'周边公交',
		required:false
	},{
		prop:'bigBusiness',
		label:'大型商务',
		required:false
	},
	
	{
		prop:'functionArea',
		label:'城市功能区域',
		type:'select',
		option:[
			{label:'城市中央商务区',value:'center'},
			{label:'城市核心商业区',value:'coreBusiness'},
			{label:'城市区域性商业区',value:'businessDistrict'},
			{label:'城市新城卫星城商业区',value:'satelliteB'},
			{label:'城市科研信息产业聚集区',value:'researchMass'},
			{label:'城市待提升改造区',value:'upside'},
			{label:'城市区域性商务区',value:'areaBusiness'},
			{label:'城市新城卫星城商务区',value:'satelliteA'},
		]
	},
	{
		prop:'characterArea',
		label:'区域内(街区)功能特点',
		type:'select',
		required:false,
		option:[
			{label:'金融、高端服务业建筑聚集（街）区',value:'highFinance'},
			{label:'商务写字楼聚集（街）区',value:'businessOffice'},
			{label:'大型综合零售商业建筑聚集（街）区',value:'retailCluster'},
			{label:'一般性商业、服务业建筑聚集（街）区',value:'commercialGathering'},
		]
	},
]