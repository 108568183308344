export const tableConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "type",
        label: "类型",
        type:'state',
        option:[
            {label:'图片',value:1},
            {label:'视频',value:2}
        ]
    },
    {
        prop: "url",
        label: "图片",
        type:'img'
    },
    {
        prop: "videoUrl",
        label: "视频",
        type:'video'
    },
    {
        prop:"status",
        label:'状态',
        type:'state',
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1}
        ]
    }
]

export const formConfig=[
    {
        prop: "name",
        label: "标题",
    },
    {
        prop: "type",
        label: "类型",
        type:'select',
        option:[
            {label:'图片',value:1},
            {label:'视频',value:2}
        ]
    },
    {
        prop: "url",
        label: "图片",
        type:'img',
        tips:'尺寸：315*183，格式：jpg/png',
        required:false
    },
    {
        prop: "videoUrl",
        label: "视频",
        type:'file',
        required:false
    },
    {
        prop:"status",
        label:'状态',
        type:'select',
        option:[
            {label:'禁用',value:0},
            {label:'启用',value:1}
        ]
    }
]