import { createRouter, createWebHashHistory } from 'vue-router'
import {getToken} from '@/utils/tools.js'

const routes= [
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '/',
        name: 'home',
        meta: {
            title: '后台管理系统',
            icon: 'education',
            roles: ['admin', 'editor']
        },
        component:() => import('@v/home.vue'),
        children: [
            {
                path: '/index',
                name: 'index',
                meta: {
                    title: '主页',
                    icon: 'education',
                    type:'custom',
                    formType:'custom'
                },
                component: () => import('@v/index.vue')
            },
            {
                path: '/enterprise/enterInfo',
                name: 'enterInfo',
                meta: {
                    title: '企业信息'
                },
                component: () => import('@v/enterprise/enterInfo.vue')
            },
            {
                path: '/enterprise/importantEp',
                name: 'importantEp',
                meta: {
                    title: '总部企业'
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/street/province',
                name: 'province',
                meta: {
                    title: '省份'
                },
                component: () => import('@v/street/province.vue')
            },
            {
                path: '/street/city',
                name: 'city',
                meta: {
                    title: '市'
                },
                component: () => import('@v/street/city.vue')
            },
            {
                path: '/street/county',
                name: 'county',
                meta: {
                    title: '区/县'
                },
                component: () => import('@v/street/county.vue')
            },
            {
                path: '/street/streetInfo',
                name: 'streetInfo',
                meta: {
                    title: '街道办事处'
                },
                component: () => import('@v/street/streetInfo.vue')
            },
            {
                path: '/ImageInput',
                name: 'ImageInput',
                meta: {
                    title: '图文输入'
                },
                component: () => import('@v/ImageInput/ImageInput.vue')
            },
            {
                path: '/street/building',
                name: 'building',
                meta: {
                    title: '大楼列表'
                },
                component: () => import('@v/street/building.vue')
            },
            // {
            //     path: '/building/detailSwiper',
            //     name: 'detailSwiper',
            //     meta: {
            //         title: '楼宇详情轮播'
            //     },
            //     component: () => import('@c/customList/detailSwiper.vue')
            // },
            {
                path: '/dictionaries/:id',
                name: 'dictionaries',
                meta: {
                    title: '字典'
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/analysis',
                name: 'analysis',
                meta: {
                    title: '楼宇分析'
                },
                component: () => import('@v/analysis/ImageText.vue')
            },
            {
                path: '/rotation/:id',
				name: 'rotation',
				meta: {
				    title: '轮播管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
            },
            {
                path: '/building/:id',
                name: 'building',
                meta: {
                    title: '楼宇',
					 // type:'default',
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/buildingSingle/:id',
                name: 'buildingSingle',
                meta: {
                    title: '楼栋',
					 // type:'default',
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/revenue/:id',
                name: 'revenue',
                meta: {
                    title: '税收楼宇',
					 // type:'default',
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/entering/:id',
                name: 'entering',
                meta: {
                    title: '税收',
					 // type:'default',
                },
                component: () => import('@c/revenue.vue')
            },
            {
                path: '/addImportantBuilding',
                name: 'addImportantBuilding',
                meta: {
                    title: '选择重点楼宇',
					 // type:'default',
                },
                component: () => import('@c/customList/addImportantBuilding.vue')
            },
            {
                path: '/dictionaries/optinDictionaries',
                name: 'optinDictionaries',
                meta: {
                    title: '字典'
                },
                component: () => import('@v/dictionaries/optinDictionaries.vue')
            },
            {
                path: '/system/:id',
                name: 'system',
                meta: {
                    title: '系统设置',
                    type:'default'
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/Right',
                name: 'Right',
                meta: {
                    title: '菜单',
                    type:'custom',
                    formType:'custom'
                },
                component: () => import('@v/system/menu.vue')
            },
			{
				path: '/userManager/:id',
				name: 'userManager',
				meta: {
				    title: '员工管理',
				    type:'default',
					// formType:'custom'
				},
				component: () => import('@c/generalList.vue')
			},
            {
                path: '/userManager/userRight',
                name: 'userRight',
                meta: {
                    title: '用户权限',
                    type:'default'
                },
                component: () => import('@v/userManager/userRight.vue')
            },
            {
                path: '/member/:id',
                name: 'member',
                meta: {
                    title: '会员管理',
                    type:'default'
                },
                component: () => import('@c/generalList.vue')
            },
            {
                path: '/userManager/userLog',
                name: 'userLog',
                meta: {
                    title: '工作日志',
                    type:'default'
                },
                component: () => import('@v/userManager/userLog.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登陆'
        },
        component: () => import('@v/login.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: '404',
        },
        component: () => import('@v/404.vue')
    }
]



const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//全局路由守卫
router.beforeEach((to, from, next) => {
    const token = getToken('token') || false
    if (to.meta.title) {
        document.title = to.meta.title
    }
    //如果没登录,都导向登录页
    if (!token) {
        if (to.path !== '/login') {
            next({ path: '/login' })
        }
        else {
            next();
        }
    }
    else {
        next();
    }
})

export default router
