

export default function(
    navList,//导航栏list
    myRequest,//请求对象包含所有请求方法
    _axios,//请求方法
    conditionQuery,//获取方法的数据对象
    searchOptionsConditionQuery,//条件搜索配置
    mode,//筛选方式 1选到区 0选到街道
    showToast,
    ringConfig,//圆环图数据
    histogramConfig,//圆柱图数据
    lineConfig,//折线图数据
    tableData,//table数据
    isshow,//是否可以展示页面
){
	
    mode.value = 1
    if(mode.value) {
        showToast.info('请选择区')
    }else {
        showToast.info('请选择街道')
    }
    searchOptionsConditionQuery.options = [
        { label: '省', prop: 'province', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '市', prop: 'city', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        { label: '区/县', prop: 'county', type: 'select', options: [], lkey: 'areaName', vkey: 'areaId' },
        // { label: '街道办', prop: 'street', type: 'select', options: [], lkey: 'areaName', vkey: 'areaName' },
        { label: '年份', prop: 'year', type: 'year' },
    ]
    getProvince()
    // 获取省份
    async function getProvince(id = 1, type = '') {
        const res = await _axios('get', `/sys/area/listByPid?pid=${id}`, {}, true)
        if (type == 'province') {
           searchOptionsConditionQuery.options[1].options = res
        } else if (type == 'city') {
           searchOptionsConditionQuery.options[2].options = res
        } else if (type == 'county') {
           searchOptionsConditionQuery.options[3].options = res
        } else {
           searchOptionsConditionQuery.options[0].options = res
           const province = searchOptionsConditionQuery.options[0].options[0]
        }

    }
    conditionQuery.getSelectVal = (e)=>{ //获取省市区街道
        const [sitem, item] = e
        if (item.prop == 'street') {
           
        }
        if (item.prop == 'province') {
            getProvince(sitem.areaId, 'province')
        }
        if (item.prop == 'city') {
            getProvince(sitem.areaId, 'city')
        }
        if (item.prop == 'county') {
            getProvince(sitem.areaId, 'county')
            console.log('获取区');
        }
    }

    myRequest.getTableData = async (e)=>{
        // console.log(63)
        let id;
        if(mode.value) {
            id = e.county
        }else {
            id = e.street
        }
        const res = await _axios('get',`/sys/building/wholeAnalysis/num?areaId=${id}`,{},true)
        tableData.value = res
        isshow.value = true
    }
    
}
